import React from "react";
import Layout from "../components/layout";
import Contact from "../components/Pages/Contact/Contact";

export default () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
};
