import React from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import API from '../../../firebase/api';
import Form from '../../Common/Form/Form';
import * as styles from "./contact.module.scss";

export default () => {

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail address.')
      .required('E-mail is required'),
    name: Yup.string()
      .required('Name is required'),
      subject: Yup.string()
      .required('Subject is required'),
      message: Yup.string()
      .required('Message is required'),
  });

  async function onSubmit(values){
    if(await API.contactForm(values)){
      toast.success('📧 Message Sent')
    }
    else{
      toast.error('Something went wrong...')
    }
  }


  return (
    <div className={styles.container}>
      <h1>Contact Us</h1>
      <p>
        For quickest responses (since I get live notifications), consider <a href="https://twitter.com/previewedapp">sending a message on Twitter</a>.
      </p>
          <Form validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{
          name: "",
          email: "",
          subject: "",
          message: ""
        }} fields={[
          {
            placeholder: 'Your Name',
            label: "Name",
            type: 'text',
            name: 'name'
        },
            {
                placeholder: 'example@mail.com',
                label: "E-mail",
                type: 'email',
                name: 'email'
            },
            {
              placeholder: 'Subject',
              label: "Subject",
              type: 'text',
              name: 'subject'
          },
          {
            placeholder: 'Type your message...',
            label: "Message",
            type: 'textarea',
            name: 'message'
        },
        ]} 
        submitButtonText={'Send'}
        />
    </div>
  )
}




     